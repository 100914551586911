import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import localforage from "localforage";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faCalendar, faCalendarDays  } from '@fortawesome/free-solid-svg-icons'


const useStyles = makeStyles(({ palette }) => ({
  certificate: {
    backgroundColor: palette.secondary.light,
    justifyContent: "center",
    maxHeight: "250px",
    minWidth: "350px"
  },
  button: {
    alignSelf: "center",
  },
}));

export const Certification = ({ job }) => {
  const classes = useStyles();
  const [jobisassigned, setAssigned] = useState(false)

  useEffect(() => {
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        let registration_api = window.API_HOST + `/inspectorassignedjob/${value}/${job.job_id}`
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                setAssigned(d["inspectorassignedjob"])
              });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });
  }, []);

  const formatLocation = (loc) => {
    let arr = loc.split(",")
    let last3 = arr.slice(-3)
    let s = ""
    last3.forEach(el => {s+=el});
    s = s.substring(2, s.length-2)
    s = s.replace(/""/g, ',');
    s = s.replace('"', ",")
    return s
  }

  if(job.location){
    return (
      <Content className={classes.certificate}>
        {(() => {
        if (jobisassigned) {
          return (
            <Chip color="primary" label="Job Assigned" />
          )
        } else if (job.inspector_has_bid_on) {
          return (
            <Chip color="primary" label="Bid Submitted"/>
          )
        } 
      })()}
        <ScalableTypography color="textSecondary">{job.name}</ScalableTypography>
        <ScalableTypography color="textSecondary">
          <FontAwesomeIcon icon={faLocationPin} color="red"/>{formatLocation(job.location)}
        </ScalableTypography>
        <ScalableTypography color="textSecondary">
          <FontAwesomeIcon icon={faCalendarDays} color="blue" /> {job.startDate} - {job.endDate}
        </ScalableTypography>
        {/* <FontAwesomeIcon icon="fa-solid fa-certificate" /> */}
        {/* <ScalableTypography color="textSecondary">{type}</ScalableTypography> */}
        {
          !jobisassigned && 
            <Button
            component={Link}
            to={`/job-details/:${job.job_id}`}
            query={{ id: job.job_id }}
            className={classes.button}
            fullWidth
            variant="contained"
            color="primary"
          >
            Read More and Submit Bid
          </Button>
        }
        
      </Content>
    );
  } else {
    return (
      <>
        <div>Loading</div>
      </>
    )
}
};
