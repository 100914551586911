import React, {useState, useRef, useCallback} from "react";
import Webcam from "react-webcam";

import { makeStyles } from "@material-ui/core/styles";
import {Dialog} from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import localforage from "localforage";

import { Header } from "../../components/Header/Header";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Footer } from "../../components/Footer/Footer";
import Button from "@material-ui/core/Button";
import { HandCertificateSvg } from "../../components/Icons/HandCertificateSvg";
import { Link } from "react-router-dom";
import { RegistrationSlide } from "../Registration/RegistrationSlide";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";
import { HeaderDetails } from "../../components/Header/HeaderDetails";

const WebcamComponent = () => <Webcam />;

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    backgroundColor: palette.secondary.dark,
  },
  button: {
    marginBottom: spacing(2),
  },
  handCertificate: {
    marginTop: "10px",
    marginBottom: 0,
    height: "33%",
    width: "33%",
    maxHeight: "30rem",
    maxWidth: "30rem",
  },
}));

export const TakePhotoCertification = ({certification}) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const [webcamImage, setWebcamImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  
  const handleCloseCategory = () => setShowWebcam(false);
  const handleShowCategory = () => setShowWebcam(true);

  const webcamRef = React.useRef(null);
  
  if(selectedImage){
    console.log(selectedImage)
  }

  const captureWebcam = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setWebcamImage(imageSrc)
      setSelectedImage(null)
    },

    [webcamRef]
  );

  const sendImage = () => {
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        let registration_api = window.API_HOST + `/uploadcertification`
        let reader = new FileReader();
        let temp = null
        if(webcamImage){
          let tempWebcamImage = webcamImage.substring(webcamImage.indexOf("/9"))
          temp = {"result": tempWebcamImage, "cert_name": certification, "inspector_token": value}
            fetch(registration_api, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(temp)
            }).then(resp => {
              resp.json().then(data => {})
            })
        } else {
          reader.readAsBinaryString(selectedImage);
          reader.onload = function() {
            temp = {"result": btoa(reader.result), "cert_name": certification, "inspector_token": value}
            fetch(registration_api, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(temp)
            }).then(resp => {
              resp.json().then(data => {})
            })
          };   
        }
    }
    })
  }

  return (
    <RegistrationSlide
      backButton={<GoToPreviousPageButton />}
      subheaderText="Certifications"
    >
      <SubHeader text={certification} />
      <Content className={classes.content} centerItems>
        <ScalableTypography>
          Please upload a photo of your {certification} certification card or
          certificate. You can also take a photo of a website on a screen. The
          image must show your certification number and expiration date.
        </ScalableTypography>
        {(() => {
          if (selectedImage) {
            return (
              <>
                <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                <br />
                <button onClick={()=>setSelectedImage(null)}>Remove</button>
              </>
            )
          } else if (webcamImage) {
            return (
              <img alt="not found" width={"250px"} src={webcamImage} />
            )
          } else {
            return (
              <HandCertificateSvg className={classes.handCertificate} />
            )
          }
        })()}
      </Content>
      <div>
        
        <Button
          className={classes.button}
          color="primary"
          fullWidth
          hidden
          variant="contained"
        >
        <input
          type="file"
          name="myImage"
          onChange={(event) => {
            setSelectedImage(event.target.files[0]);
            setWebcamImage(null);
          }}
        />
        </Button>
        <ScalableTypography color="primary">
            <Button
                className={classes.button}
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleShowCategory}
                >
                    Open Webcam
            </Button>
        </ScalableTypography>

        <Dialog
                open={showWebcam}
                onClose={handleCloseCategory}
                scroll="paper">
                <DialogContent dividers={true}>
                    <div>
                        <Webcam
                        audio={false}
                        height={200}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={220}/>
                    </div>
                    <div>
                    <Button variant="secondary" onClick={handleCloseCategory}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => {
                      captureWebcam()
                      handleCloseCategory()
                    }
                  }
                    >
                        Save Changes
                    </Button>
                    </div>
                </DialogContent>
            </Dialog>
      </div>
      <Footer>
        <Button
          className={classes.button}
          fullWidth
          color="primary"
          variant="contained"
          component={Link}
          to={`/cert-upload-success/:${certification}`}
          onClick={(e)=>{
            sendImage()
          }}
        >
          Submit Image
        </Button>
        <ScalableTypography>
          We will retain confirmation of certifications in accordance with our
          Privacy Policy and will not share them without your prior consent.
        </ScalableTypography>
      </Footer>
      </RegistrationSlide>
  );
};
