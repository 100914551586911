import React, { useEffect, useState }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { Footer } from "../../components/Footer/Footer";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";
import { RegistrationSlide } from "./RegistrationSlide";
import { Select } from "../../components/Form/Select";
import { TextInput } from "../../components/Form/TextInput";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: spacing(2),
  },
}));

export const PhoneNumberEntrySlide = ({setPhoneNumber}) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState("+1")
  const [phoneNo, setPhoneNo] = useState("")

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value)
  }

  const handlePhoneChange = (event) => {
    setPhoneNo(event.target.value)
    setPhoneNumber(event.target.value)
  }

  const submitPhoneNumber = () => {
    let phone_number = countryCode+phoneNo
    setPhoneNumber(phone_number)
    console.log("Phone number here: \n\n")
    console.log(phone_number)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "phone_number": phone_number,
    })
    };
    let submit_phone_api = window.API_HOST + "/start_verification"
    fetch(submit_phone_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in bid submission")
          }
        });
  }

  return (
    <RegistrationSlide
      backButton={<GoToPreviousPageButton />}
      subheaderText="Phone Number Entry"
    >
      <Content>
        <ScalableTypography color="textSecondary">
          Enter Phone Number
        </ScalableTypography>
        <Content centerItems>
        <ScalableTypography sizing="title" color="textSecondary">
          We use your phone number in order to send you notifications on jobs
          and will provide your contact information when you submit a bid,
          including your phone number.
        </ScalableTypography>
        <ScalableTypography sizing="title" color="textSecondary">
          After clicking submit please wait for a text and navigate to the next page.
        </ScalableTypography>
        <Select onChange={handleCountryCodeChange}>
          <option value="+1">United States (+1)</option>
        </Select>
        <TextInput onChange={handlePhoneChange}/>
      </Content>
      <div className={classes.footer}>

        <Button
          className={classes.button}
          color="primary"
          onClick = {() => submitPhoneNumber()}
          variant="contained"
          fullWidth
        >
          Submit
        </Button>
      </div>
      </Content>
      <Footer>
        <GoToNextPageButton/>
      </Footer>
    </RegistrationSlide>)
};
