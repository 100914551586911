import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Footer } from "../../components/Footer/Footer";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { RegistrationSlide } from "./RegistrationSlide";
import Button from "@material-ui/core/Button";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";

const useStyles = makeStyles(({}) => ({}));

export const WorkEligibilitySlide = ({setEligibleCountries, countries}) => {
  const [selectedCountries, setSelectedCountries] = useState(null)

  // #C9D7DD == Not Selected
  // #6DDA43 == Selected

  useEffect(() => {
    let temp = {}
    if(countries && !selectedCountries){
      for (const country of countries){
        temp[country] = "#C9D7DD"
      }
      setSelectedCountries(temp)
    }
  }, [countries]);

  return ( !selectedCountries ? (
    <h1>Loading</h1>
  ) : ( 
    <RegistrationSlide
      backButton={<GoToPreviousPageButton />}
      subheaderText="WORK ELIGIBILITY"
    >
      <Content>
        <ScalableTypography color="textSecondary">
          Now, tell us in which country or countries you are eligible to work.
        </ScalableTypography>
        <Content centerItems padding={false}>
          {countries.map((country) => (
            <Button variant="contained" fullWidth style={{ backgroundColor: selectedCountries[country] }} key={country}
            onClick={() => {
              if (selectedCountries[country] === "#C9D7DD"){
                setSelectedCountries((countries) => {
                  countries[country] = "#6DDA43"
                  return {...countries};
                })
                setEligibleCountries((locations) => {
                  return locations.concat([country])
                })
              } else {
                setSelectedCountries((countries) => {
                  countries[country] = "#C9D7DD"
                  return {...countries};
                })
                setEligibleCountries((locations) => {
                  return locations.filter(l => l !== country)
                })
              }
            }}>
              {country}
            </Button>
          ))}
        </Content>
      </Content>
      <Footer>
        <GoToNextPageButton />
      </Footer>
    </RegistrationSlide>)
  );
};
