import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import DraftsIcon from "@material-ui/icons/Drafts";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { IconFooter } from "../../components/Footer/IconFooter";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Divider } from "../../components/Divider/Divider";
import localforage from "localforage";
import { TextInput } from "../../components/Form/TextInput";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(({ spacing }) => ({
  noMailIcon: {
    fontSize: "10rem",
  },
  mailIcon: {
    marginRight: spacing(1),
  },
  textHolder: {
    display: "flex",
  },
  date: {
    marginLeft: "auto",
  },
}));

export const Messages = ({}) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([])
  const [currMessage, setCurrMessage] = useState("")
  const [token, setToken] = useState()

  useEffect(() => {
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        setToken(value)
        let registration_api = window.API_HOST + `/listmessages/${value}/`
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                console.log(d)
                setMessages(d["messages"])
              });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });
  }, []);

  console.log(currMessage)

  const sendMessage = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        {
        "message": currMessage,
        "token": token
      })
    };
    console.log("Message Sent")
    let send_message_api = window.API_HOST + "/sendmessage"
    fetch(send_message_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in message send")
          } else {
            setMessages(data["updated_messages"]["messages"])
            setCurrMessage("")
          }
        });
  }

  console.log(messages)

  return (
    <>
      <Header>
        <HeaderDetails backTo="/job-feed" text="MESSAGES" />
      </Header>
      <SubHeader text="Your Inbox" centerText={false} />
      <div style={{overflow:"auto", display:"flex", flexDirection:"column-reverse"}}>
      <Content centerItems style={{"overflow-y":"scroll", display: "flex"}}>
        {messages.length === 0 ? (
          <>
            <ScalableTypography sizing="title" color="secondary">
              Feel free to message our admin.
            </ScalableTypography>
            <MailIcon color="primary" className={classes.noMailIcon} />
            {/* <ScalableTypography color="secondary">
              CorrosionOne is home to innovative coatings experts and employers.
            </ScalableTypography>
            <ScalableTypography color="secondary">
              Submit a proposal on a job posting!
            </ScalableTypography> */}
          </>
        ) : (
          messages.map((message) => (
            <>
              <div style={{width: "100%",
                           color: "#444",
                          lineHeight: "26px",
                          fontSize: "16px",
                          borderRadius: "7px",
                          display: "inline-block",
                          position: "relative",
                          wordWrap: "break-word"
                          }}>
                <div className={classes.textHolder}>
                  <ScalableTypography
                    className={classes.date}
                    sizing="title"
                    color="secondary"
                  >
                    {message.date}
                  </ScalableTypography>
                </div>
                <div>
                  {message.from_admin ? (
                    <ScalableTypography color="textSecondary" style={{color: "black", maxWidth: "60%", textAlign: "left", background: "#eee", borderRadius: "30px", width: "max-content", padding: "7px", marginRight: "auto"}}>
                    {message.message}
                    </ScalableTypography>
                  ) : (
                    <ScalableTypography color="textSecondary" style={{color: "white", maxWidth: "60%", textAlign: "right", borderRadius: "30px", width: "max-content", padding: "7px", marginLeft: "auto", background: "linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%)"}}>
                                      {message.message}
                                    </ScalableTypography>
                  )}
                </div>
              </div>
            </>
          ))
        )}
      </Content>
      </div>
      <Content centerItems>
        {/* {messages.length != 0 &&  */}
            <>
              <TextInput style={{padding: "0", borderRadius: "0", backgroundColor: "inherit", width: "100%"}} value={currMessage} onChange={(e)=>{setCurrMessage(e.target.value)}}/>
              <Button color="primary"
                  variant="contained"
                  fullWidth 
                  onClick={sendMessage}>Send Message to Admin
              </Button>
            </>
          {/* } */}
      </Content>
      <IconFooter />
    </>
  );
};
