import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import { Header } from "../../components/Header/Header";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Certification } from "./Certification";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import Button from "@material-ui/core/Button";
import { RegistrationSlide } from "../Registration/RegistrationSlide";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Footer } from "../../components/Footer/Footer";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { CustomGoToNextPageButton } from "../../components/ControlledSwipeableView/CustomGoToNextPageButton";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";
import { Link } from "react-router-dom";
import { BackButton } from "../../components/BackButton/BackButton";

const useStyles = makeStyles(({ spacing }) => ({
  subHeader: {
    display: "flex",
    alignItems: "center",
    padding: spacing(1),
  },
  certificationsText: {
    margin: "auto",
  },
}));

export const ChooseCertification = ({setCertification, certs, verified_certs}) => {
  const classes = useStyles();

  console.log(verified_certs)

  if (verified_certs){
    return (
      // <RegistrationSlide
      //   backButton={<BackButton component={Link} to="/job-feed" />}
      //   subheaderText="Certifications"
      // >
      <>
        <Header>
          <HeaderDetails text="Certifications" backTo="/job-feed" />
        </Header>
        <Content>
          <ScalableTypography color="textSecondary">
            Tap on a certification to verify it.
          </ScalableTypography>
          <Content centerItems padding={false}>
          {certs.map((certification) => {
            if (certification in verified_certs){
              if (verified_certs[certification]){
                return (
                  <Button variant="contained" fullWidth key={certification}
                  onClick={() => {
                    setCertification(certification)
                  }}>
                    <CustomGoToNextPageButton interior={
                      (<div className={classes.buttonText}>
                        <div>{certification}<Check color="primary"/></div>
                      </div>)
                    }/>
                  </Button>
                )
              }
            } else {
              return (
                <Button variant="contained" fullWidth key={certification}
                onClick={() => {
                  setCertification(certification)
                }}>
                  <CustomGoToNextPageButton interior={
                    (
                      <div className={classes.buttonText}>
                        <div>{certification}</div>
                      </div>
                    )
                  } />
                </Button>
              )
            }
            })}
          </Content>
        </Content>
        {/* <div className={classes.footer}>

        <Footer>
          <GoToNextPageButton />
        </Footer>
        </div> */}
              {/* </RegistrationSlide> */}
      </>
    );
  } else {
    return (
      <div>Loading...</div>
    )
  }

  
};
