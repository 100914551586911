import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import { YourLocationSlide } from "./YourLocationSlide";
import { WorkEligibilitySlide } from "./WorkEligibilitySlide";
import { CertificationsSlide } from "./CertificationsSlide";
import { LanguagesSlide } from "./LanguagesSlide";
import { PhoneNumberEntrySlide } from "./PhoneNumberEntrySlide";
import { IndustryExperienceSlide } from "./IndustryExperienceSlide";
import { ControlledSwipeableView } from "../../components/ControlledSwipeableView/ControlledSwipeableView";
import { Navigate } from "react-router-dom";
import { PhoneCodeConfirmationSlide } from "./PhoneCodeConfirmationSlide";


const useStyles = makeStyles({});

export const Registration = ({ ...props }) => {
  const [location, setLocation] = useState()
  const [eligibleCountries, setEligibleCountries] = useState([])
  const [languages, setLanguages] = useState([])
  const [certifications, setCertifications] = useState([])
  const [experiences, setExperiences] = useState([])
  const [phoneNumber, setPhoneNumber] = useState("")

  const [locationOptions, setLocationOptions] = useState()
  const [eligibleCountriesOptions, setEligibleCountriesOptions] = useState([])
  const [languagesOptions, setLanguagesOptions] = useState([])
  const [certificationsOptions, setCertificationsOptions] = useState([])
  const [experiencesOptions, setExperiencesOptions] = useState([])
  const [registrationComplete, setRegistrationComplete] = useState(false)

  const submitRegistration = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "eligibleCountries": eligibleCountries, 
        "languages": languages,
        "certifications": certifications,
        "experiences": experiences,
        "location": location,
        "phone_number": phoneNumber,
        "token": window.C1TOKEN
    })
    };
    let registration_api = window.API_HOST + "/registration"
    fetch(registration_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in registration")
          }
        });
  }

  useEffect(() => {
    console.log(window.C1TOKEN)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "token": window.C1TOKEN
    })
    };
    let registrationdata_api = window.API_HOST + "/getregistrationdata"
    fetch(registrationdata_api, requestOptions).then(res => {
            return res.text();
          }).then(data => {
            let d = JSON.parse(data)
            console.log(d)
            if(d["status"] === 1){
              setRegistrationComplete(true)
            } else {
              console.log("Registration incomplete")
            }
            setLocationOptions(d["countries"])
            setEligibleCountriesOptions(d["countries"])
            setLanguagesOptions(d["languages"])
            setCertificationsOptions(d["certs"])
            setExperiencesOptions(d["exps"])
          });
  }, []);

  if(registrationComplete){
    return (
      <Navigate to="/job-feed" />
    )
  } else if (!experiencesOptions){
    return (
      <h1>Loading</h1>
    )
  } else {
    return (
      <ControlledSwipeableView disabled={false} disableLazyLoading={true}>
        <YourLocationSlide setLocation = {setLocation} countries = {locationOptions}/>
        <WorkEligibilitySlide setEligibleCountries = {setEligibleCountries} countries = {locationOptions}/>
        <LanguagesSlide setLanguages = {setLanguages} languagesOptions = {languagesOptions}/>
        <CertificationsSlide setCertifications = {setCertifications} certs = {certificationsOptions}/>
        <IndustryExperienceSlide setExperiences = {setExperiences} exps = {experiencesOptions}/>
        <PhoneNumberEntrySlide setPhoneNumber={setPhoneNumber} />
        <PhoneCodeConfirmationSlide submitRegistration={submitRegistration} phone={phoneNumber} />
      </ControlledSwipeableView>)
  }
};