import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Footer } from "../../components/Footer/Footer";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { RegistrationSlide } from "./RegistrationSlide";
import Button from "@material-ui/core/Button";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";

const useStyles = makeStyles(({}) => ({}));

export const LanguagesSlide = ({setLanguages, languagesOptions}) => {
  const [selectedLanguages, setSelectedLanguages] = useState({})

  useEffect(() => {
    let temp = {}
    if(languagesOptions && !selectedLanguages){
      for (const lang of languagesOptions){
        temp[lang] = "#C9D7DD"
      }
      setSelectedLanguages(temp)
    }
  }, [languagesOptions]);


  return ( !selectedLanguages ? (
    <h1>Loading</h1>
  ) : ( 
    <RegistrationSlide
      backButton={<GoToPreviousPageButton />}
      subheaderText="Languages"
    >
      <Content>
        <ScalableTypography color="textSecondary">
          Next, select the languages you are fluent in.
        </ScalableTypography>
        <Content centerItems padding={false}>
          {languagesOptions.map((language) => (
              <Button variant="contained" fullWidth style={{ backgroundColor: selectedLanguages[language] }} key={language}
              onClick={() => {
                if (selectedLanguages[language] === "#C9D7DD"){
                  setSelectedLanguages((langs) => {
                    langs[language] = "#6DDA43"
                    return {...langs};
                  })
                  setLanguages((langs) => {
                    return langs.concat([language])
                  })
                } else {
                  setSelectedLanguages((langs) => {
                    langs[language] = "#C9D7DD"
                    return {...langs};
                  })
                  setLanguages((langs) => {
                    return langs.filter(l => l !== language)
                  })
                }
              }}>
                {language}
              </Button>
            ))}
        </Content>
      </Content>
      <Footer>
        <GoToNextPageButton/>
      </Footer>
    </RegistrationSlide>)
  );
};
