import React from "react";
import {useState, useEffect} from "react";
import { LandingPage } from "./scenes/LandingPage/LandingPage";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "./scenes/Login/Login";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Registration } from "./scenes/Registration/Registration";
import { PushNotifications } from "./scenes/PushNotifications/PushNotifications";
import { JobFeed } from "./scenes/JobFeed/JobFeed";
import { ChooseCertification } from "./scenes/CertificateVerification/ChooseCertification";
import { UploadCertificationFlow } from "./scenes/CertificateVerification/UploadCertificationFlow";
import { TakePhotoCertification } from "./scenes/CertificateVerification/TakePhotoCertification";
import { PhotoPreviewCertification } from "./scenes/CertificateVerification/PhotoPreviewCertification";
import { JobDetails } from "./scenes/JobDetails/JobDetails";
import { SubmitBid } from "./scenes/JobDetails/SubmitBid";
import { BidSubmissionSuccess } from "./scenes/JobDetails/BidSubmissionSuccess";
import { Messages } from "./scenes/Messages/Messages";
import { Profile } from "./scenes/Profile/Profile";
import { AuthContext, useAuth } from "./components/Auth/auth";
import { RequireAuth } from "./components/Auth/RequireAuth";
import { ConfirmPhoneNumber } from "./scenes/PhoneNumberCollection/ConfirmPhoneNumber";
import { NotFound404 } from "./scenes/NotFound404/NotFound404";
import { EnterPhoneNumber } from "./scenes/PhoneNumberCollection/EnterPhoneNumber";
import { SuccessfullyConfirmedPhoneNumber } from "./scenes/PhoneNumberCollection/SuccessfullyConfirmedPhoneNumber";
import { CertifactionUploadSuccess } from "./scenes/CertificateVerification/CertificationUploadSuccess";

const useStyles = makeStyles({
  app: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
});

const App = () => {
  const classes = useStyles();
  const [authState, setAuthState] = useAuth();

  // useEffect(() => {
  //   fetch(`http://localhost:8001/session?user_email=${localStorage.getItem("user_email")}`)
  //     .then(response => response.json())
  //       .then(data => {
  //         console.log(data["sessionValid"])
  //         setSession(data["sessionValid"])
  //         // Idk what this line below does
  //         // this.setState({ postId: data.id })
  //       });
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        authResponse: authState.authResponse,
        setAuthState,
        authType: authState.authType,
        msal: authState.msal,
      }}
    >
      <div className={classes.app}>
        <Routes>
          {/* <Route exact path="/" element={session ? <Navigate to="/job-feed" /> : <LandingPage />}>
          </Route> */}
          <Route exact path="/" element={<LandingPage />}>
          </Route>
          <Route path="/landing-page" element={<LandingPage/>}>
          </Route>
          <Route path="/login" element={<Login/>}>
          </Route>
          <Route path="/registration" element={<Registration/>}>
          </Route>
          <Route path="/push-notifications" element={<PushNotifications/>}>
          </Route>
          <Route path="/job-feed" element={<JobFeed/>}>
          </Route>
          <Route path="/cert-upload-flow" element={<UploadCertificationFlow/>}>
          </Route>
          <Route path="/choose-certification-type" element={<ChooseCertification/>}>
          </Route>
          <Route path="/take-photo-certification" element={<TakePhotoCertification/>}>
          </Route>
          <Route path="/photo-preview-certification" element={<PhotoPreviewCertification/>}>
          </Route>
          <Route path="/job-details/:id" element={<JobDetails/>}>
          </Route>
          <Route path="/submit-bid/:id" element={<SubmitBid/>}>
          </Route>
          <Route path="/bid-submission-success/:job/:email" element={<BidSubmissionSuccess/>}>
          </Route>
          <Route path="/cert-upload-success/:cert" element={<CertifactionUploadSuccess/>}>
          </Route>
          <Route path="/messages" element={<Messages/>}>
          </Route>
          <Route path="/profile" element={<Profile/>}>
          </Route>
          <Route path="/enter-phone-number" element={<EnterPhoneNumber/>}>
          </Route>
          <Route path="/confirm-phone-number" element={<ConfirmPhoneNumber/>}>
          </Route>
          <Route path="/successfully-confirmed-phone-number" element={<SuccessfullyConfirmedPhoneNumber/>}>
          </Route>
          <Route element={NotFound404} />
        </Routes>
      </div>
    </AuthContext.Provider>
  );
};

export default App;
