import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Footer } from "../../components/Footer/Footer";
import { RegistrationSlide } from "./RegistrationSlide";
import Button from "@material-ui/core/Button";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  button: {
    margin: "auto",
  },
});

export const IndustryExperienceSlide = ({setExperiences, exps}) => {
  const classes = useStyles();

  const [selectedExperiences, setSelectedExperiences] = useState(null)

  // const experiences = ["Marine", "Deep Water", "Pipelines", "Terminals", "Petroleum", "Food & Beverages"]

  useEffect(() => {
    let temp = {}
    if(exps && ! selectedExperiences){
      for (const exp of exps){
        temp[exp] = "#C9D7DD"
      }
      setSelectedExperiences(temp)
    }
  }, [exps]);


  return (
    <RegistrationSlide
      backButton={<GoToPreviousPageButton />}
      subheaderText="Industry Experience"
    >
      <Content>
        <ScalableTypography color="textSecondary">
          Select the industries you are experienced in.
        </ScalableTypography>
        <Content centerItems padding={false}>
        {exps.map((experience) => (
            <Button variant="contained" fullWidth style={{ backgroundColor: selectedExperiences[experience] }} key={experience}
            onClick={() => {
              if (selectedExperiences[experience] == "#C9D7DD"){
                setSelectedExperiences((experiences) => {
                  experiences[experience] = "#6DDA43"
                  return {...experiences};
                })
                setExperiences((exps) => {
                  return exps.concat([experience])
                })
              } else {
                setSelectedExperiences((experiences) => {
                  experiences[experience] = "#C9D7DD"
                  return {...experiences};
                })
                setExperiences((exps) => {
                  return exps.filter(l => l !== experience)
                })
              }
            }}>
              {experience}
            </Button>
          ))}
        </Content>
      </Content>
      <Footer>
        {/* to should be /push-notifications but changed to job-feed */}
        {/* <Button
          fullWidth
          className={classes.button}
          variant="contained"
          onClick = {submitRegistration}
          component={Link}
          to="/job-feed"
          color="primary"
        >
          Submit
        </Button> */}
        <GoToNextPageButton/>
      </Footer>
    </RegistrationSlide>
  );
};
