import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/Header/Header";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Tabs } from "../../components/Tabs/Tabs";
import { Tab } from "../../components/Tabs/Tab";
import { Available } from "./Available";
import { Upcoming } from "./Upcoming";
import { History } from "./History"
import localforage from "localforage";
import { Content } from "../../components/Content/Content";
import { IconFooter } from "../../components/Footer/IconFooter";
import { useNavigate, useLocation, Navigate } from "react-router-dom";


const useStyles = makeStyles(({ palette, spacing }) => ({
  subHeader: {
    display: "flex",
    alignItems: "center",
    margin: spacing(1),
    justifyContent: "center",
  },
  icon: {
    marginRight: spacing(1),
  },
  tabs: {
    backgroundColor: palette.secondary.main,
    "& .Mui-selected": {
      backgroundColor: palette.primary.main,
    },
  },
  indicator: {
    backgroundColor: palette.primary.main,
  },
  tab: {
    fontSize: "1.75vh",
    fontWeight: 800,
    flexGrow: 1,
  },
  iconHolder: {
    display: "flex",
    margin: "auto",
  },
  footerIcon: {
    fontSize: "2rem",
    margin: spacing(0, 2),
  },
}));

export const JobFeed = ({}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState("Available");
  const [jobs, setJobs] = useState([])
  const [token, setToken] = useState("notset")

  useEffect(() => {
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
        setToken("false")
      } else {
        console.log("local forage value:")
        console.log(value)
        setToken(value)
        let registration_api = window.API_HOST + `/listjobs/${value}/`
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                console.log(d)
                setJobs(d)
              });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });
  }, []);
  
  if (token !== "false"){
    return (
      <>
        <Header>
          <div className={classes.subHeader}>
            <AccountCircleIcon className={classes.icon} color="primary" />
            <ScalableTypography sizing="title">Your Job Feed</ScalableTypography>
          </div>
        </Header>
        <Tabs value={tabValue} onChange={setTabValue}>
          <Tab value="Available" />
          <Tab value="Upcoming" />
          <Tab value="History" />
        </Tabs>
        {tabValue === "Available" && (
          <Available certifications={jobs} />
        )}
        {tabValue === "Upcoming" && (
          <Upcoming certifications={jobs} />
        )}
        {tabValue === "History" && (
          <History certifications={jobs} />
        )}
        <IconFooter />
      </>
    );
  } else {
    return <Navigate to="/login" />
  }
};
