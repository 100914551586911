import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import MessageIcon from "@material-ui/icons/Message";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import localforage from "localforage";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faCalendar, faCertificate, faCalendarDays  } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    borderRadius: 0,
    padding: spacing(1),
  },
  icon: {
    marginRight: spacing(0.5),
  },
}));

const get_billable_days = (job) => {
  let date1 = new Date(job.startDate);
  let date2 = new Date(job.endDate);
  
  let time_diff = date2.getTime() - date1.getTime();
    
  let day_diff = time_diff / (1000 * 3600 * 24);
  return parseInt(day_diff)
}

export const JobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState()
  useEffect(() => {
    let jobId = parseInt(id.substring(1))
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        let registration_api = window.API_HOST + `/job/${jobId}/${value}`
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                let loc = d["location"]
                let locationArray = loc.split(",")
                let vagueLocation = locationArray.slice(locationArray.length-3).join()
                vagueLocation = vagueLocation.replace(/"/g, "")
                vagueLocation = vagueLocation.replace(/}/g, "")
                d["location"] = vagueLocation
                setJob(d)
        });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });
  }, []);
  const classes = useStyles();

  if(job){
    return (
      <>
        <Header>
          <HeaderDetails text="Job Details" backTo="/job-feed" />
        </Header>
        <SubHeader centerText={false}>{job["name"]}</SubHeader>
        <Content>
          <ScalableTypography color="textSecondary">
            <FontAwesomeIcon icon={faLocationPin} color="red"/> {job["location"]}
          </ScalableTypography>
          <ScalableTypography color="textSecondary">
            <FontAwesomeIcon icon={faCalendarDays} color="blue" style={{marginRight: "4px"}}/>  
            {job["startDate"]} - {job["endDate"]}
          </ScalableTypography>
          <ScalableTypography color="secondary">
            {get_billable_days(job)} billable days
          </ScalableTypography>
          <Card elevation={0}>
            <CardContent>
              <ScalableTypography color="textSecondary" style={{fontWeight: "bold", marginBottom: "10px"}}>
                Requirements
              </ScalableTypography>
              <ScalableTypography color="textSecondary" style={{marginBottom: "5px", fontWeight: "bold", fontSize: "small"}}>
                <FontAwesomeIcon icon={faCertificate} color="orange"/> {job["cert"]} Certification
              </ScalableTypography>
              <ScalableTypography color="textSecondary" style={{marginBottom: "5px", fontWeight: "bold", fontSize: "small"}}>
                {job["country"]} Work Authorization
              </ScalableTypography>
              <ScalableTypography color="textSecondary" style={{marginBottom: "5px", fontWeight: "bold", fontSize: "small"}}>
                {job["lang"]} Language Requirement
              </ScalableTypography>
            </CardContent>
          </Card>
          <ScalableTypography color="secondary">Description</ScalableTypography>
          <ScalableTypography color="textSecondary">
            {job["description"]}
          </ScalableTypography>
          <ScalableTypography color="secondary">Additional Details</ScalableTypography>
          <ScalableTypography color="textSecondary">
            {job["logistics"]}
          </ScalableTypography>
          <ScalableTypography color="secondary">Financial Accomodations</ScalableTypography>
          <ScalableTypography color="textSecondary">
            Travel: ${job["travel"]}
          </ScalableTypography>
          <ScalableTypography color="textSecondary">
            Per Diem: ${job["perdiem"]}
          </ScalableTypography>
        </Content>
        <div className={classes.footer} style={{marginBottom: "20px", marginLeft: "10px", marginRight: "10px"}}>
          {!job.inspector_has_bid_on &&
            <Button
              className={classes.button}
              style={{marginRight: "10px"}}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              component={Link}
              to={`/submit-bid/:${job.job_id}`}
              >
                Submit Bid
              </Button>
          }
          <Button
            className={classes.button}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={"/messages"}
          >
            <MessageIcon className={classes.icon} color="primary" />
              Message Owner
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>Loading</div>
      </>
    )
  }

  
};
