import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import DraftsIcon from "@material-ui/icons/Drafts";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { IconFooter } from "../../components/Footer/IconFooter";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Divider } from "../../components/Divider/Divider";
import { Link, useParams } from "react-router-dom";
import localforage from "localforage";
import { TextInput } from "../../components/Form/TextInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faCalendar, faCertificate, faCalendarDays, faMailBulk, faPerson, faBank  } from '@fortawesome/free-solid-svg-icons'
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(({ spacing }) => ({
  noMailIcon: {
    fontSize: "10rem",
  },
  mailIcon: {
    marginRight: spacing(1),
  },
  textHolder: {
    display: "flex",
  },
  date: {
    marginLeft: "auto",
  },
}));

export const Profile = ({}) => {
  const classes = useStyles();
  const [userData, setUserData] = useState({})
  const [token, setToken] = useState()

  useEffect(() => {
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        setToken(value)
        let registration_api = window.API_HOST + `/getuserinfo/${value}/`
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                console.log(d)
                setUserData(d)
              });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });
  }, []);

  const userLogout = () => {
    localforage.clear().then(function() {
        // Run this code once the database has been entirely deleted.
        console.log('User is now logged out.');
        delete window.C1TOKEN;
    }).catch(function(err) {
        // This code runs if there were any errors
        console.log(err);
    });
  }


  const linkBank = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"userToken": token})
    };
    console.log("Bid submitted")
    let stripe_onboarding_api = window.API_HOST + "/onboard-user"
    fetch(stripe_onboarding_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in stripe onboarding")
          } else {
            window.location = data["url"];
          }
        });
  }

  return (
    <>
      <Header>
        <HeaderDetails backTo="/job-feed" text="PROFILE" />
      </Header>
      <SubHeader text="Your Profile" centerText={false} />
      <div style={{overflow:"auto", display:"flex"}}>
        <Content style={{"overflow-y":"scroll", display: "flex", alignItems: 'flex-start'}}>
            <ScalableTypography sizing="title" color="secondary">
              <FontAwesomeIcon icon={faMailBulk} color="blue"/> 
              {userData["email"]}
            </ScalableTypography>
            <ScalableTypography sizing="title" color="secondary">
              <FontAwesomeIcon icon={faPerson} color="blue"/> 
                {userData["name"]}
            </ScalableTypography>
            {userData["needs_connect_bank"] && 
              <ScalableTypography sizing="title" color="secondary">
                <FontAwesomeIcon icon={faBank} color="blue"/> 
                <Button color="primary"
                    variant="contained"
                    onClick={linkBank}>
                      Link Bank Account
                </Button>
              </ScalableTypography>
            }
        </Content>
      </div>
      <Content centerItems>
            <>
              <Button color="primary"
                  variant="contained"
                  fullWidth 
                  onClick={userLogout}
                  component={Link}
                  to={"/login"}>
                        Logout
              </Button>
            </>
      </Content>
      <IconFooter />
    </>
  );
};
