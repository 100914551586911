import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  cls1: {
    fill: "#204355",
  },
  cls2: {
    fill: "#f0efef",
  },
  cls3: {
    fill: "#7dc245",
  },
});

export const LandingPageSvg = ({ ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1310.4642 869.28878"
      {...props}
    >
      <rect className={classes.cls1} width="1310.4642" height="869.28878" />
      <path
        className={classes.cls2}
        d="M785.30782,259.33916l-35.19541,35.449a102.08447,102.08447,0,1,1-63.3065-58.86854l18.13818-43.70613A149.43609,149.43609,0,0,0,549.589,227.86313c-57.98225,58.40039-57.64186,153.085.75874,211.06745,58.40033,57.982,153.085,57.64179,211.06682-.75877C809.58591,389.65294,817.49614,316.09312,785.30782,259.33916Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls3}
        d="M702.15477,242.26453c2.22057,1.13967,4.41385,2.34962,6.56289,3.66279L636.362,318.80486l33.48959,33.25,54.8219-55.21681,17.53429-17.66077,33.87488-34.11935a149.40734,149.40734,0,0,0-55.79154-46.495Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M184.47292,708.61061h6.27953v27.13221h-6.27953Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M241.46322,708.61061v27.13221h-5.15649l-13.5292-16.47124v16.47124h-6.20572V708.61061h5.19867l13.487,16.47125V708.61061Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M269.97682,735.33157a14.36737,14.36737,0,0,1-4.82432-2.26717l2.13008-4.72415a14.46446,14.46446,0,0,0,4.14945,2.05627,15.58629,15.58629,0,0,0,4.80851.76979,7.7176,7.7176,0,0,0,3.95436-.79088,2.39434,2.39434,0,0,0,1.27594-2.11954,1.99718,1.99718,0,0,0-.754-1.60283,5.73066,5.73066,0,0,0-1.94028-1.03341c-.7856-.25308-1.85064-.53779-3.19512-.8436a46.401,46.401,0,0,1-5.07741-1.47629,8.295,8.295,0,0,1-3.39548-2.36208,6.40185,6.40185,0,0,1-1.413-4.34453,7.553,7.553,0,0,1,1.276-4.28125,8.572,8.572,0,0,1,3.85946-3.0475,15.74884,15.74884,0,0,1,6.30062-1.12831,20.98617,20.98617,0,0,1,5.0774.62215,15.1376,15.1376,0,0,1,4.34453,1.7821l-1.94028,4.77687a15.1295,15.1295,0,0,0-7.51856-2.14063,7.03239,7.03239,0,0,0-3.89637.85415,2.59557,2.59557,0,0,0-1.26012,2.24608,2.19846,2.19846,0,0,0,1.44993,2.07735,23.0559,23.0559,0,0,0,4.43943,1.33922,44.16193,44.16193,0,0,1,5.07741,1.47629,8.40114,8.40114,0,0,1,3.39548,2.3199,6.25106,6.25106,0,0,1,1.413,4.30234,7.42483,7.42483,0,0,1-1.297,4.24963,8.83053,8.83053,0,0,1-3.89636,3.0475,15.91877,15.91877,0,0,1-6.32171,1.11776A21.902,21.902,0,0,1,269.97682,735.33157Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M329.40828,709.80219a8.99518,8.99518,0,0,1,5.5203,8.64688,9.40925,9.40925,0,0,1-1.43412,5.21976,9.07132,9.07132,0,0,1-4.08618,3.406,15.26321,15.26321,0,0,1-6.26371,1.181H317.677v7.48693h-6.27953V708.61061h11.7471A15.26,15.26,0,0,1,329.40828,709.80219Zm-2.30934,12.11618a4.85179,4.85179,0,0,0,0-6.97023,6.54126,6.54126,0,0,0-4.30235-1.22322H317.677v9.41666h5.11959A6.54131,6.54131,0,0,0,327.09894,721.91837Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M379.78689,730.70233v5.04049H358.77076V708.61061h20.51v5.03H365.01338v5.89464h12.60124v4.88232H365.01338v6.28481Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M409.58171,734.40361a13.44219,13.44219,0,0,1-5.27249-4.99831,14.57279,14.57279,0,0,1,0-14.45716,13.39418,13.39418,0,0,1,5.27249-5.00886,15.65355,15.65355,0,0,1,7.53965-1.80319,15.87605,15.87605,0,0,1,6.37444,1.2443,12.80255,12.80255,0,0,1,4.78742,3.5642L424.255,716.667a8.66742,8.66742,0,0,0-6.8226-3.174,9.0411,9.0411,0,0,0-4.49743,1.10722,7.80506,7.80506,0,0,0-3.08441,3.07914,9.72873,9.72873,0,0,0,0,8.99486,7.87329,7.87329,0,0,0,3.08441,3.07913,9.041,9.041,0,0,0,4.49743,1.10722,8.64337,8.64337,0,0,0,6.8226-3.21621l4.02818,3.72237A12.5651,12.5651,0,0,1,423.48,734.9625a15.87982,15.87982,0,0,1-6.4008,1.2443A15.5751,15.5751,0,0,1,409.58171,734.40361Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M457.23971,713.72492h-8.68379v-5.11431H472.2083v5.11431h-8.68379v22.0179h-6.2848Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M494.95907,708.61061h6.27953v27.13221h-6.27953Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M532.58881,734.38252a13.49115,13.49115,0,0,1-5.3094-5.0194,14.43414,14.43414,0,0,1,0-14.38335,13.49115,13.49115,0,0,1,5.3094-5.0194,16.77717,16.77717,0,0,1,15.21639,0,13.84121,13.84121,0,0,1,0,24.42215,16.77717,16.77717,0,0,1-15.21639,0Zm11.958-4.62924a7.95608,7.95608,0,0,0,3.0475-3.07913,9.72873,9.72873,0,0,0,0-8.99486,7.88579,7.88579,0,0,0-3.0475-3.07914,9.06682,9.06682,0,0,0-8.68378,0,7.87271,7.87271,0,0,0-3.04222,3.07914,9.72873,9.72873,0,0,0,0,8.99486,7.94282,7.94282,0,0,0,3.04222,3.07913,9.06682,9.06682,0,0,0,8.68378,0Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M604.05735,708.61061v27.13221h-5.15649l-13.5292-16.47124v16.47124h-6.20571V708.61061h5.19867l13.487,16.47125V708.61061Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M684.92147,735.74282l-.0369-16.28143-7.98782,13.4132h-2.83133l-7.94563-13.06522v15.93345h-5.89464V708.61061h5.19867L675.57863,725.472l10.0019-16.86141H690.737l.07909,27.13221Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M734.5092,729.93254H721.908l-2.40425,5.81028h-6.43243l12.09508-27.13221h6.20044l12.13726,27.13221h-6.5906Zm-1.97718-4.77687-4.30235-10.3868-4.30235,10.3868Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M765.71178,708.61061h12.32707a17.41827,17.41827,0,0,1,7.81382,1.67665,12.64773,12.64773,0,0,1,5.27249,4.75578,14.45913,14.45913,0,0,1,0,14.26735,12.69455,12.69455,0,0,1-5.27249,4.74524,17.28062,17.28062,0,0,1-7.81382,1.68719H765.71178ZM777.733,730.58633a9.13188,9.13188,0,0,0,6.49043-2.26717,8.99085,8.99085,0,0,0,0-12.28489,9.09087,9.09087,0,0,0-6.49043-2.27771h-5.74173v16.82977Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M838.13464,730.70233v5.04049H817.11851V708.61061h20.51v5.03H823.36114v5.89464h12.60124v4.88232H823.36114v6.28481Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M895.56256,735.33157a14.36737,14.36737,0,0,1-4.82432-2.26717l2.13008-4.72415a14.46446,14.46446,0,0,0,4.14945,2.05627,15.58629,15.58629,0,0,0,4.80851.76979,7.7176,7.7176,0,0,0,3.95436-.79088,2.39434,2.39434,0,0,0,1.27594-2.11954,1.99721,1.99721,0,0,0-.754-1.60283,5.73066,5.73066,0,0,0-1.94028-1.03341c-.7856-.25308-1.85064-.53779-3.19513-.8436a46.40138,46.40138,0,0,1-5.0774-1.47629,8.29488,8.29488,0,0,1-3.39548-2.36208,6.4018,6.4018,0,0,1-1.413-4.34453,7.553,7.553,0,0,1,1.27594-4.28125,8.57212,8.57212,0,0,1,3.85946-3.0475,15.74886,15.74886,0,0,1,6.30063-1.12831,20.98617,20.98617,0,0,1,5.0774.62215,15.1376,15.1376,0,0,1,4.34453,1.7821l-1.94028,4.77687a15.1295,15.1295,0,0,0-7.51856-2.14063,7.03239,7.03239,0,0,0-3.89637.85415,2.59557,2.59557,0,0,0-1.26012,2.24608,2.19846,2.19846,0,0,0,1.44993,2.07735,23.0559,23.0559,0,0,0,4.43943,1.33922,44.16193,44.16193,0,0,1,5.07741,1.47629,8.40114,8.40114,0,0,1,3.39548,2.3199,6.25106,6.25106,0,0,1,1.413,4.30234,7.42482,7.42482,0,0,1-1.297,4.24963,8.83053,8.83053,0,0,1-3.89636,3.0475,15.91877,15.91877,0,0,1-6.32171,1.11776A21.902,21.902,0,0,1,895.56256,735.33157Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M936.98848,708.61061H943.268v27.13221h-6.27954Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M993.7837,735.74282l-.0369-16.28143-7.98782,13.4132h-2.83132L974.982,719.80937v15.93345h-5.89464V708.61061h5.19867L984.44086,725.472l10.0019-16.86141h5.1565l.07908,27.13221Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M1043.50325,709.80219a8.99519,8.99519,0,0,1,5.52029,8.64688,9.40917,9.40917,0,0,1-1.43412,5.21976,9.07134,9.07134,0,0,1-4.08617,3.406,15.2633,15.2633,0,0,1-6.26372,1.181H1031.772v7.48693h-6.27954V708.61061h11.7471A15.26,15.26,0,0,1,1043.50325,709.80219Zm-2.30935,12.11618a4.85179,4.85179,0,0,0,0-6.97023,6.54124,6.54124,0,0,0-4.30235-1.22322H1031.772v9.41666h5.11958A6.54129,6.54129,0,0,0,1041.1939,721.91837Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M1072.86572,708.61061h6.27953v22.0179h13.60829v5.11431h-19.88782Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M1136.68389,730.70233v5.04049h-21.01613V708.61061h20.51v5.03h-14.26734v5.89464h12.60124v4.88232h-12.60124v6.28481Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M132.82575,670.10146a45.62225,45.62225,0,0,1-18.31786-17.071,49.99745,49.99745,0,0,1,0-49.76858,45.63315,45.63315,0,0,1,18.31786-17.071,55.84029,55.84029,0,0,1,26.26414-6.107,57.66407,57.66407,0,0,1,23.89936,4.728A44.37732,44.37732,0,0,1,200.323,598.46866L180.75665,615.933q-8.53709-10.76571-20.09184-10.76809-9.71946,0-15.62663,6.23759-5.90955,6.23919-5.90955,16.74293,0,10.50772,5.90955,16.74293,5.90955,6.23919,15.62663,6.2376,11.554,0,20.09184-10.76809l19.56633,17.4659a44.36281,44.36281,0,0,1-17.33373,13.65678,57.66392,57.66392,0,0,1-23.89936,4.72636A55.85863,55.85863,0,0,1,132.82575,670.10146Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M223.82582,670.03617a47.12287,47.12287,0,0,1,0-83.77994,60.65139,60.65139,0,0,1,53.31495,0,47.12385,47.12385,0,0,1,0,83.77994,60.66552,60.66552,0,0,1-53.31495,0Zm37.09752-21.73364a20.223,20.223,0,0,0,7.41919-8.01,28.13923,28.13923,0,0,0,0-24.2927,20.24879,20.24879,0,0,0-7.41919-8.01157,20.7137,20.7137,0,0,0-20.88009,0,20.2261,20.2261,0,0,0-7.41919,8.01157,28.12451,28.12451,0,0,0,0,24.2927,20.2004,20.2004,0,0,0,7.41919,8.01,20.7137,20.7137,0,0,0,20.88009,0Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M348.0506,650.99535h-8.40491v23.1111h-30.9905V582.186h44.25395q12.73635,0,22.19226,4.20086A33.9759,33.9759,0,0,1,390.3347,634.055a31.52477,31.52477,0,0,1-12.87011,11.68693l19.30357,28.36457H363.67723Zm12.343-42.08664q-3.15064-2.82341-9.45432-2.8234h-11.2936V627.6199h11.2936q6.30367,0,9.45432-2.82181,3.153-2.82339,3.15144-7.94469Q363.54505,611.73212,360.39361,608.90871Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M440.88994,650.99535H432.485v23.1111H401.49453V582.186h44.25395q12.73635,0,22.19227,4.20086A33.97588,33.97588,0,0,1,483.174,634.055a31.52471,31.52471,0,0,1-12.87011,11.68693l19.30358,28.36457H456.51657Zm12.343-42.08664q-3.15064-2.82341-9.45433-2.8234H432.485V627.6199h11.29359q6.30367,0,9.45433-2.82181,3.153-2.82339,3.15144-7.94469Q456.3844,611.73212,453.233,608.90871Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M515.87005,670.03617a47.12288,47.12288,0,0,1,0-83.77994,60.65139,60.65139,0,0,1,53.31495,0,47.12385,47.12385,0,0,1,0,83.77994,60.66552,60.66552,0,0,1-53.31495,0Zm37.09752-21.73364a20.223,20.223,0,0,0,7.41919-8.01,28.13923,28.13923,0,0,0,0-24.2927,20.24879,20.24879,0,0,0-7.41919-8.01157,20.7137,20.7137,0,0,0-20.88009,0,20.2261,20.2261,0,0,0-7.41919,8.01157,28.12451,28.12451,0,0,0,0,24.2927,20.2004,20.2004,0,0,0,7.41919,8.01,20.7137,20.7137,0,0,0,20.88009,0Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M612.716,673.64624a59.65771,59.65771,0,0,1-17.79394-6.89368l9.97983-22.5856a63.47455,63.47455,0,0,0,14.70779,6.10542,57.42072,57.42072,0,0,0,15.62663,2.29789q6.43266,0,9.19157-1.24688t2.75811-3.74224q0-2.88789-3.61166-4.33462a77.84737,77.84737,0,0,0-11.88439-3.15144,134.64126,134.64126,0,0,1-17.72706-4.79325,30.18333,30.18333,0,0,1-12.343-8.40332q-5.25506-5.84266-5.25347-15.82409a27.25627,27.25627,0,0,1,4.85854-15.75721q4.85616-7.09194,14.51033-11.16142,9.65259-4.07028,23.44074-4.07188a85.2215,85.2215,0,0,1,18.58061,2.03514,58.30855,58.30855,0,0,1,16.08684,5.97485l-9.32374,22.455q-13.527-6.8244-25.60646-6.82839-11.95048,0-11.94968,5.779,0,2.7565,3.54477,4.13557a75.15965,75.15965,0,0,0,11.68693,2.95557,120.09,120.09,0,0,1,17.72865,4.53049,30.85914,30.85914,0,0,1,12.54048,8.338q5.31715,5.78056,5.31875,15.75721a26.96136,26.96136,0,0,1-4.86013,15.69351q-4.85853,7.02505-14.51033,11.16142-9.65019,4.13716-23.43914,4.13557A96.42491,96.42491,0,0,1,612.716,673.64624Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M681.984,582.186h30.99051v91.9205H681.984Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M744.48893,670.03617a47.12287,47.12287,0,0,1,0-83.77994,60.65137,60.65137,0,0,1,53.31494,0,47.12385,47.12385,0,0,1,0,83.77994,60.6655,60.6655,0,0,1-53.31494,0Zm37.09752-21.73364a20.223,20.223,0,0,0,7.41918-8.01,28.13914,28.13914,0,0,0,0-24.2927,20.24877,20.24877,0,0,0-7.41918-8.01157,20.71372,20.71372,0,0,0-20.8801,0,20.22615,20.22615,0,0,0-7.41918,8.01157,28.12451,28.12451,0,0,0,0,24.2927,20.20045,20.20045,0,0,0,7.41918,8.01,20.71372,20.71372,0,0,0,20.8801,0Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls2}
        d="M919.92662,582.186v91.9205H894.45234l-34.93021-41.75859v41.75859H829.31829V582.186h25.47588l34.93021,41.75859V582.186Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls3}
        d="M951.441,670.03617a47.12288,47.12288,0,0,1,0-83.77994,60.65139,60.65139,0,0,1,53.31495,0,47.12385,47.12385,0,0,1,0,83.77994,60.66552,60.66552,0,0,1-53.31495,0Zm37.09752-21.73364a20.223,20.223,0,0,0,7.41919-8.01,28.13923,28.13923,0,0,0,0-24.2927,20.24879,20.24879,0,0,0-7.41919-8.01157,20.7137,20.7137,0,0,0-20.88009,0,20.2261,20.2261,0,0,0-7.41919,8.01157,28.12451,28.12451,0,0,0,0,24.2927,20.2004,20.2004,0,0,0,7.41919,8.01,20.7137,20.7137,0,0,0,20.88009,0Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls3}
        d="M1126.87715,582.186v91.9205h-25.47429l-34.93021-41.75859v41.75859h-30.20384V582.186h25.47588l34.93021,41.75859V582.186Z"
        transform="translate(-0.2679 -0.35561)"
      />
      <path
        className={classes.cls3}
        d="M1213.28143,650.73259v23.37386h-76.426V582.186h74.71895V605.5598H1167.322v10.76809h38.86831v22.32285H1167.322v12.08185Z"
        transform="translate(-0.2679 -0.35561)"
      />
    </SvgIcon>
  );
};
