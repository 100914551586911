import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Footer } from "../../components/Footer/Footer";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { RegistrationSlide } from "./RegistrationSlide";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";

export const CertificationsSlide = ({setCertifications, certs}) => {
  const [selectedCertifications, setSelectedCertifications] = useState(null)

  // const certifications = ["NACE1", "NACE2", "NACE3", "SSPC", "Six Sigma", "Cisco"]

  useEffect(() => {
    let temp = {}
    if(certs && ! selectedCertifications){
      for (const cert of certs){
        temp[cert] = "#C9D7DD"
      }
      setSelectedCertifications(temp)
    }
  }, [certs]);

  return (
    <RegistrationSlide
      backButton={<GoToPreviousPageButton />}
      subheaderText="Certifications"
    >
      <Content>
        <ScalableTypography color="textSecondary">
          Tell us what certifications you hold.
        </ScalableTypography>
        <Content centerItems padding={false}>
        {certs.map((certification) => (
              <Button variant="contained" fullWidth style={{ backgroundColor: selectedCertifications[certification] }} key={certification}
              onClick={() => {
                if (selectedCertifications[certification] === "#C9D7DD"){
                  setSelectedCertifications((certifications) => {
                    certifications[certification] = "#6DDA43"
                    return {...certifications};
                  })
                  setCertifications((certs) => {
                    return certs.concat([certification])
                  })
                } else {
                  setSelectedCertifications((certifications) => {
                    certifications[certification] = "#C9D7DD"
                    return {...certifications};
                  })
                  setCertifications((certs) => {
                    return certs.filter(l => l !== certification)
                  })
                }
              }}>
                {certification}
              </Button>
            ))}
        </Content>
      </Content>
      <Footer>
        <GoToNextPageButton />
      </Footer>
    </RegistrationSlide>
  );
};
