import React, { useEffect, useState }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { Footer } from "../../components/Footer/Footer";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { GoToPreviousPageButton } from "../../components/ControlledSwipeableView/GoToPreviousPageButton";
import { RegistrationSlide } from "./RegistrationSlide";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { TextInput } from "../../components/Form/TextInput";
import { Navigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: spacing(2),
  },
}));

export const PhoneCodeConfirmationSlide = ({submitRegistration, phone}) => {
  const classes = useStyles();
  const [confirmationCode, setConfirmationCode] = useState("")
  const [isFinished, setIsFinished] = useState("false")

  const handleConfirmCodeChange = (event) => {
    setConfirmationCode(event.target.value)
  }

  const submitVerificationCode = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "phone_number": phone,
        "code": confirmationCode,
        "userToken": window.C1TOKEN
    })
    };
    let submit_phone_api = window.API_HOST + "/check_phone_verification"
    fetch(submit_phone_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in phone verification")
          } else {
            submitRegistration()
            setIsFinished("true")
          }
        });
  }

//   else if (isFinished == "no") {
//     return (
//         <RegistrationSlide
//         subheaderText="Confirmation"
//         >
//         <Content>
//             <ScalableTypography color="textSecondary">
//                 Phone Number Confirmation
//             </ScalableTypography>
//             <Content centerItems>
//                 <ScalableTypography sizing="title" color="textSecondary">
//                 You've entered an incorrect code, please return to the previous page and try again.
//                 </ScalableTypography>
//             </Content>
//             <Button
//                 className={classes.button}
//                 color="primary"
//                 onClick = {() => {setIsFinished("false")}}
//                 variant="contained"
//                 fullWidth
//                 >
//                 <GoToPreviousPageButton />
//             </Button>
//         </Content>
//         </RegistrationSlide>
//     )
//   } 

  if(isFinished == "true"){
    return <Navigate to="/job-feed" />
  } else {
    return (
        <RegistrationSlide
        backButton={<GoToPreviousPageButton/>}
        subheaderText="Confirmation"
        >
        <Content>
        <ScalableTypography color="textSecondary">
            Phone Number Confirmation
        </ScalableTypography>
        <Content centerItems>
                <ScalableTypography sizing="title" color="textSecondary">
                We've sent you a text message with a four digit confirmation code.
                Please enter that number below. If you click submit and are not redirected,
                please navigate back and ensure your phone number and code are correct.
                </ScalableTypography>
                <TextInput onChange={handleConfirmCodeChange}/>
            </Content>
        </Content>
        <Footer>
        {/* to should be /push-notifications but changed to job-feed */}
        <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick = {() => submitVerificationCode()}
            fullWidth
        >
            Submit
        </Button>
        </Footer>
        </RegistrationSlide>
      );
  }
};
