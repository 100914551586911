import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import { ControlledSwipeableView } from "../../components/ControlledSwipeableView/ControlledSwipeableView";
import { ChooseCertification } from "./ChooseCertification";
import { TakePhotoCertification } from "./TakePhotoCertification";
import { Navigate } from "react-router-dom";
import localforage from "localforage";


const useStyles = makeStyles({});

export const UploadCertificationFlow = ({ ...props }) => {
  const [certification, setCertification] = useState([])
  const [certificationImage, setCertificationImage] = useState([])

  const [certificationsOptions, setCertificationsOptions] = useState([])
  const [verifiedCerts, setVerifiedCerts] = useState()
  const [token, setToken] = useState("notset")

  useEffect(() => {
    localforage.getItem('C1TOKEN', function (err, value) {
        if (err){
          console.log("local forage error:")
          console.log(err)
          setToken("false")
        } else {
          console.log("local forage value:")
          console.log(value)
          setToken(value)
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "token": value
            })
            };
            let registrationdata_api = window.API_HOST + "/getunverifiedcerts"
            fetch(registrationdata_api, requestOptions).then(res => {
                    return res.text();
                }).then(data => {
                    let d = JSON.parse(data)
                    setCertificationsOptions(d["certs"])
                    setVerifiedCerts(d["certs_verified"])
                });
        }
        // if err is non-null, we got an error. otherwise, value is the value
      });
  }, []);

if (token !== "false"){
    if (!certificationsOptions){
        return (
        <h1>Loading</h1>
        )
    } else {
        return (
        <ControlledSwipeableView disabled={false} disableLazyLoading={true}>
            <ChooseCertification setCertification={setCertification} certs={certificationsOptions} verified_certs={verifiedCerts}/>
            <TakePhotoCertification certification={certification}/>
        </ControlledSwipeableView>)
    }
} else {
    return <Navigate to="/login" />
}
};
