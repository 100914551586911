import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import MessageIcon from "@material-ui/icons/Message";
import localforage from "localforage";
import { Select } from "../../components/Form/Select";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faCalendar, faCalendarDays } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles(({ palette, spacing }) => ({
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  cost: {
    backgroundColor: palette.primary.main,
    marginRight: spacing(1),
    borderRadius: spacing(1),
    padding: spacing(0.5),
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: spacing(2),
  },
}));

export const SubmitBid = ({}) => {
  const classes = useStyles();
  const [bid, setBid] = useState(1200)
  const [insurance, setInsurance] = useState("no insurance carried")
  const { id } = useParams();

  const [job, setJob] = useState()
  const [email, setEmail] = useState()
  const [token, setToken] = useState()
  useEffect(() => {
    let jobId = parseInt(id.substring(1))
    console.log(jobId)
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        let registration_api = window.API_HOST + `/job/${jobId}/${value}`
        setToken(value)
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                let loc = d["location"]
                let locationArray = loc.split(",")
                let vagueLocation = locationArray.slice(locationArray.length-3).join()
                vagueLocation = vagueLocation.replace(/"/g, "")
                vagueLocation = vagueLocation.replace(/}/g, "")
                d["location"] = vagueLocation
                setEmail(d["inspector_email"])
                setJob(d)
        });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });
  }, []);

  const handleBidChange = (event) => {
    console.log(event.target.value)
    setBid(event.target.value);
  }

  const handleInsuranceChange = (event) => {
    setInsurance(event.target.value)
  }

  const submitBid = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "jobId": job.job_id,
        "price": bid,
        "insurance": insurance,
        "token": token
    })
    };
    console.log("Bid submitted")
    let submit_bid_api = window.API_HOST + "/submitbid"
    fetch(submit_bid_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in bid submission")
          }
        });
  }

  if(job){
    return (
      <>
        <Header>
          <HeaderDetails text="SUBMIT BID" backTo={`/job-details/:${job.job_id}`} />
        </Header>
        <SubHeader text="Shipyard Inspections" centerText={false} />
        <Content>
          <ScalableTypography color="secondary">
            Price Per Day
          </ScalableTypography>
          <div className={classes.textContainer}>
            <ScalableTypography sizing="header" className={classes.cost} >
            <form style={{margin: "0"}}>
              $<input style={{backgroundColor : "#6DDA43", border: "none", color: "white", fontSize:"24pt", height: "100%", margin: "0", "textAlign": "right"}}type="number" name="bid" value={bid} onChange={handleBidChange}/>
            </form>
            </ScalableTypography>
            <ScalableTypography sizing="header" color="secondary">
              USD
            </ScalableTypography>
          </div>
          <ScalableTypography color="secondary">
            Professional Indemnity Insurance
          </ScalableTypography>
          <ScalableTypography color="textSecondary" style={{fontWeight: "bold", fontSize: "small"}}>
            If you have professional indemnity insurance, please select amount:
          </ScalableTypography>
          <Select onChange={handleInsuranceChange}>
            <option value="no-insurance">No Insurance Carried</option>
            <option value="<1M" style={{paddingLeft: "10px"}}>&#60;1M</option>
            <option value="1-2M" style={{paddingLeft: "10px"}}>1-2M</option>
            <option value="2-3M" style={{paddingLeft: "10px"}}>2-3M</option>
            <option value="3-4M" style={{paddingLeft: "10px"}}>3-4M</option>
            <option value=">4M" style={{paddingLeft: "10px"}}>&#62;4M</option>
          </Select>
          <ScalableTypography color="secondary" style={{paddingBottom: "5px"}}>
            Confirm Location and Dates
          </ScalableTypography>
          <ScalableTypography color="textSecondary">
          <FontAwesomeIcon icon={faLocationPin} color="red"/>{job["location"]}
          </ScalableTypography>
          <ScalableTypography color="textSecondary">
            <FontAwesomeIcon icon={faCalendarDays} color="blue" style={{marginRight: "4px"}}/>  
                {job["startDate"]} - {job["endDate"]}
          </ScalableTypography>
          <ScalableTypography color="textSecondary" style={{fontWeight: "bold", fontSize: "small"}}>
            By submitting this bid, I certify that I am able to work at the
            location and dates shown above.
          </ScalableTypography>
        </Content>
        <div className={classes.footer} style={{marginBottom: "20px", marginLeft: "10px", marginRight: "10px"}}>
          <Button
            className={classes.button}
            style={{marginRight: "10px"}}
            onClick = {() => submitBid()}
            color="primary"
            variant="contained"
            fullWidth
            component={Link}
            to={`/bid-submission-success/:${job["name"]}/:${email}`}
          >
            Submit Bid
          </Button>
          <Button
            className={classes.button}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            component={Link}
            to={"/messages"}
          >
            <MessageIcon className={classes.icon} color="primary" />
              Message Owner
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>Loading</div>
      </>
    )
  }
};
