import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { Certification } from "./Certification";
import classNames from "classnames";
import localforage from "localforage";
import Button from "@material-ui/core/Button";
import { CertificateSingleSvg } from "../../components/Icons/CertificateSingleSvg";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette }) => ({
  noCertifications: {
    backgroundColor: palette.secondary.dark,
  },
  certificateSingle: {
    margin: "auto",
    width: "50%",
    height: "50%",
    maxWidth: "30rem",
    maxHeight: "30rem",
  },
}));

export const Upcoming = ({ certifications }) => {
  const classes = useStyles();
  const [upcoming_jobs, setUpcomingJobs] = useState([])

  useEffect(() => {
    localforage.getItem('C1TOKEN', function (err, value) {
      if (err){
        console.log("local forage error:")
        console.log(err)
      } else {
        console.log("local forage value:")
        console.log(value)
        let registration_api = window.API_HOST + `/get_inspector_id/${value}`
        fetch(registration_api).then(res => {
                return res.text();
              }).then(data => {
                let d = JSON.parse(data)
                let inspector_id = d["inspector_id"]
                console.log("INSPECTOR ID: ")
                console.log(inspector_id)
                console.log(certifications)
                let temp = certifications.filter(cert => (cert.assigned_inspector_id == inspector_id || cert.inspector_has_bid_on));
                setUpcomingJobs(temp)
        });
      }
      // if err is non-null, we got an error. otherwise, value is the value
    });    
  }, []);

  return (
    <Content
      centerItems
      className={classNames({
        [classes.noCertifications]: upcoming_jobs.length === 0,
      })}
      style={{"overflow-y": "scroll"}}
    >
      {upcoming_jobs.length > 0 ? (
        upcoming_jobs.map((certification) => (
          <Certification job={certification} key={certification.job_id}/>
        ))
      ) : (
        <>
        <ScalableTypography sizing="title" color="textPrimary">
            You have no work assigned yet, see list of available jobs to bid on
          </ScalableTypography>
{/*         
          <ScalableTypography sizing="title" color="textPrimary">
            Verify your certifications to access the best jobs
          </ScalableTypography>
          <CertificateSingleSvg className={classes.certificateSingle} />
          <ScalableTypography color="textPrimary">
            Use your hard-earned certifications to impress job posters and score
            high-quality work.
          </ScalableTypography>
          <Button
            component={Link}
            to={"/choose-certification-type"}
            fullWidth
            color="primary"
            variant="contained"
          >
            Upload Certifications
          </Button> */}
        </>
      )}
    </Content>
  );
};
