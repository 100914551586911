import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { RegistrationSlide } from "./RegistrationSlide";
import { Footer } from "../../components/Footer/Footer";
import { GoToNextPageButton } from "../../components/ControlledSwipeableView/GoToNextPageButton";
import { BackButton } from "../../components/BackButton/BackButton";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(({}) => ({}));

export const YourLocationSlide = ({setLocation, countries}) => {
  const classes = useStyles();

  const [selectedCountry, setSelectedCountry] = useState("")

  // const countries = ["United States", "Canada", "United Kingdom", "Saudi Arabia", "European Union"]

  return ( !countries ? (
    <h1>Loading</h1>
  ) : ( 
  <RegistrationSlide
    backButton={<BackButton component={Link} to="/login" />}
    subheaderText="YOUR LOCATION"
  >
    <Content>
      <ScalableTypography color="textSecondary">
        To make a CorrosionOne profile, you need to answer a few questions
        about yourself.
      </ScalableTypography>
      <ScalableTypography color="textSecondary">
        First tell us where you're located.
      </ScalableTypography>
      <Content centerItems padding={false}>
      {countries.map((country) => {
        let color = "#C9D7DD"
        if(country === selectedCountry){
          color = "#6DDA43"
        }
        return (
          <Button variant="contained" fullWidth style={{ backgroundColor: color }} key={country}
          onClick={() => {
            setSelectedCountry(country)
            setLocation(country)
          }}>
            {country}
          </Button>
        )
        })}
      </Content>
    </Content>
    <Footer>
      <GoToNextPageButton />
    </Footer>
  </RegistrationSlide>)
  );
};
