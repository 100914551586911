import React from "react";
import { useEffect, useState } from "react";
import localforage from "localforage";
import { Header } from "../../components/Header/Header";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import {
  AuthContext,
} from "../../components/Auth/auth";
import Button from "@material-ui/core/Button";

export const Login = () => {
  const history = useNavigate();
  const location = useLocation();
  const search = useLocation().search;

  const backend_link = window.API_HOST + '/oauth/begin'
  const apple_link = window.API_HOST + '/oauth/apple/begin'
  const microsoft_link = window.API_HOST + '/oauth/microsoft/begin'

  const onFailure = (res) => {
    console.log("LOGIN FAILURE: ", res);
  };

  const redirect = () => {
    const redirectFrom = location.state?.redirectedFrom;
    if (redirectFrom !== undefined && redirectFrom !== null) {
      history.push({ pathname: redirectFrom, state: {} });
    } else {
      history("/push-notifications");
      // history.push("/push-notifications");
    }
  };

  const [token, setToken] = useState()
  const [registrationComplete, setRegistrationComplete] = useState(false)

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    localforage.setItem('C1TOKEN', token, function (err) {
      // if err is non-null, we got an error
      localforage.getItem('C1TOKEN', function (err, value) {
        if (err){
          console.log("local forage error:")
          console.log(err)
        } else {
          console.log("local forage value:")
          console.log(value)
        }
        // if err is non-null, we got an error. otherwise, value is the value
      });
    });
    window.C1TOKEN = token
    setToken(token)
    console.log(window.C1TOKEN)
  }, []);

  return (
    <AuthContext.Consumer>
      {({ setAuthState }) => 
        ( token ? (
          <Navigate to="/registration" />
        ) : (
              <>
                <Header />
                <SubHeader text="YOUR ACCOUNT" />
                <Content centerItems style={{paddingLeft: 0, paddingRight: 0}}>
                  <Button variant="contained" color="primary" href={backend_link} style={{
                    backgroundColor: "#4285f4",
                    alignItems: "center",
                    alignSelf: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    flex: "1 1 1",
                    flexDirection: "row",
                    fontWeight: "800",
                    height: "38px",
                    justifyContent: "left",
                    margin: "8px 0",
                    minHeight: "38px",
                    minWidth: "140px",
                    padding: "4px",
                    width: "90%"
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "5px",
                    }}>
                      <img src="googlelogo.svg" style={{
                        height: "30px",
                      }}>
                      </img>
                      <div style={{
                        marginLeft: "17px"
                      }}>Sign in With Google</div>
                    </div>
                  </Button>
                  <Button variant="contained" color="primary" href={apple_link}
                  style={{
                    backgroundColor: "black",
                    alignItems: "center",
                    alignSelf: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    flex: "1 1 1",
                    flexDirection: "row",
                    fontWeight: "800",
                    height: "38px",
                    justifyContent: "left",
                    margin: "8px 0",
                    minHeight: "38px",
                    minWidth: "140px",
                    padding: "4px",
                    width: "90%"
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "5px",
                    }}>
                      <img src="applelogo.svg" style={{
                        height: "30px",
                      }}>
                      </img>
                      <div style={{
                        marginLeft: "17px"
                      }}>Sign in With Apple</div>
                    </div>
                  </Button>
                  <Button variant="contained" color="primary" href={microsoft_link}
                  style={{
                    backgroundColor: "#FFFFFF",
                    alignItems: "center",
                    alignSelf: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    flex: "1 1 1",
                    flexDirection: "row",
                    fontWeight: "800",
                    color: "#5E5E5E",
                    height: "38px",
                    justifyContent: "left",
                    margin: "8px 0",
                    minHeight: "38px",
                    minWidth: "140px",
                    padding: "4px",
                    width: "90%"
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "5px",
                    }}>
                      <img src="microsoftlogo.svg" style={{
                        height: "30px",
                      }}>
                      </img>
                      <div style={{
                        marginLeft: "17px"
                      }}>Sign in With Microsoft</div>
                    </div>
                  </Button>
                  <SubHeader style={{
                    marginTop: "25px"
                  }}>
                  By logging into our website, you are giving us permission to send you occasional emails about our products and services. We understand your privacy is important and we respect your decision to unsubscribe from our emails at any time. If you have any questions or concerns about our use of your email address, please don't hesitate to contact us. We appreciate your support and look forward to serving you.
                  </SubHeader>

                </Content>
              </>
            )
        )
      }
    </AuthContext.Consumer>
  );
};