import React, { useEffect, useState }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/Header/Header";
import { HeaderDetails } from "../../components/Header/HeaderDetails";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { Content } from "../../components/Content/Content";
import { ScalableTypography } from "../../components/Typography/ScalableTypography";
import { TextInput } from "../../components/Form/TextInput";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: spacing(2),
  },
}));

export const ConfirmPhoneNumber = ({}) => {
  const classes = useStyles();
  const [confirmationCode, setConfirmationCode] = useState("")

  const handleConfirmCodeChange = (event) => {
    setConfirmationCode(event.target.value)
  }

  const submitVerificationCode = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "phone_number": '+19259008867',
        "code": confirmationCode,
        "userToken": "asdf"
    })
    };
    let submit_phone_api = window.API_HOST + "/check_phone_verification"
    fetch(submit_phone_api, requestOptions)
        .then(response => response.json())
        .then((data) => {
          console.log(data)
          if(data["status"] !== 200){
            console.log("error in phone verification")
          }
        });
  }

  return (
    <>
      <Header>
        <HeaderDetails backTo="/enter-phone-number" text="SETTINGS" />
      </Header>
      <SubHeader text="Confirm Phone Number" centerText={false} />
      <Content centerItems>
        <ScalableTypography sizing="title" color="textSecondary">
          We've sent you a text message with a four digit confirmation code.
          Please enter that number below
        </ScalableTypography>
        <TextInput onChange={handleConfirmCodeChange}/>
      </Content>
      <div className={classes.footer}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick = {() => submitVerificationCode()}
          fullWidth
          component={Link}
          to="/successfully-confirmed-phone-number"
        >
          Submit
        </Button>
      </div>
    </>
  );
};
